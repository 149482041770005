// App.js - WEB
import React, { Component,lazy, Suspense } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from 'jwt-decode';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'

const NewEmailAccountRegistration = lazy(() => import("../../blocks/email-account-registration/src/NewEmailAccountRegistration.web"));
const ClinicianAccountVerification = lazy(() => import("../../blocks/email-account-registration/src/ClinicianAccountVerification.web"));
const HospitalAccountVerification = lazy(() => import("../../blocks/email-account-registration/src/HospitalAccountVerification.web"));
const EmailAccountLoginBlock = lazy(() => import("../../blocks/email-account-login/src/EmailAccountLogin.web"));
const OrderManagement= lazy(() => import("../../blocks/ordermanagement/src/OrderManagement"));
const NavigationMenu= lazy(() => import("../../blocks/navigationmenu/src/NavigationMenu"));
const PhoneNumberInput= lazy(() => import("../../blocks/mobile-account-registration/src/PhoneNumberInput"));
const AdditionalDetailForm= lazy(() => import("../../blocks/mobile-account-registration/src/AdditionalDetailForm"));
const OnboardingHome= lazy(() => import("../../blocks/user-profile-basic/src/OnboardingHome.web"));
const OnboardingEducation= lazy(() => import("../../blocks/user-profile-basic/src/Education/Education.web"));
const OnboardingEducationEdit= lazy(() => import("../../blocks/user-profile-basic/src/Education/EducationEdit.web"));
const OnboardingRefrence= lazy(() => import("../../blocks/user-profile-basic/src/Reference/Refrence.web"));
const OnboardingRefrenceEdit= lazy(() => import("../../blocks/user-profile-basic/src/Reference/RefrenceEdit.web"));
const OnboardingCertificateLicense= lazy(() => import("../../blocks/user-profile-basic/src/MyLicenses/LicenceCertificateStates.web"));
const OnboardingEMRChatting= lazy(() => import("../../blocks/user-profile-basic/src/EMRChartingExperience/EMRChatting.web"));
const OnboardingCertificateLicenseEdit= lazy(() => import("../../blocks/user-profile-basic/src/MyLicenses/EditLicenceCertificateStates.web"));
const EducationalUserProfile= lazy(() => import("../../blocks/educational-user-profile/src/EducationalUserProfile"));
const HospitalForgotPassword= lazy(() => import("../../blocks/forgot-password/src/HospitalForgotPassword.web"));
const HospitalUserProfile= lazy(() => import("../../blocks/user-profile-basic/src/Hospital/UserProfile.web"));
const HospitalUserPayment= lazy(() => import("../../blocks/user-profile-basic/src/Hospital/Payment/HospitalPayment.web"));
const HospitalResetPassword= lazy(() => import("../../blocks/forgot-password/src/HospitalResetPassword.web"));
const AdvancedSearch= lazy(() => import("../../blocks/advancedsearch/src/AdvancedSearch"));
const Emailnotifications2= lazy(() => import("../../blocks/emailnotifications2/src/Emailnotifications2"));
const Cfmatchingalgorithm11= lazy(() => import("../../blocks/cfmatchingalgorithm11/src/Cfmatchingalgorithm11"));
const OTPInputAuth= lazy(() => import("../../blocks/otp-input-confirmation/src/OTPInputAuth"));
const Adminconsole2= lazy(() => import("../../blocks/adminconsole2/src/Adminconsole2"));
const Notifications= lazy(() => import("../../blocks/notifications/src/Notifications"));
const FormApprovalWorkflow= lazy(() => import("../../blocks/formapprovalworkflow/src/FormApprovalWorkflow"));
const Paymentadmin2= lazy(() => import("../../blocks/paymentadmin2/src/Paymentadmin2"));
const Dashboardguests= lazy(() => import("../../blocks/dashboardguests/src/Dashboardguests"));
const CountryCodeSelector= lazy(() => import("../../blocks/country-code-selector/src/CountryCodeSelector"));
const Catalogue= lazy(() => import("../../blocks/catalogue/src/Catalogue"));
const UserProfileBasicBlock= lazy(() => import("../../blocks/user-profile-basic/src/UserProfileBasicBlock.web"));
const UserPublicProfileBasicBlock= lazy(() => import("../../blocks/user-profile-basic/src/PublicProfileBasicBlock.web"));
const PublicProfileBasicBlock= lazy(() => import("../../blocks/user-profile-basic/src/PublicProfileBasicBlock.web"));
const Pushnotifications= lazy(() => import("../../blocks/pushnotifications/src/Pushnotifications"));
const Scheduling= lazy(() => import("../../blocks/scheduling/src/Scheduling"));
const Settings2= lazy(() => import("../../blocks/settings2/src/Settings2"));
const Rolesandpermissions= lazy(() => import("../../blocks/rolesandpermissions/src/Rolesandpermissions"));
const Favourites= lazy(() => import("../../blocks/favourites/src/Favourites"));
const AddFavourites= lazy(() => import("../../blocks/favourites/src/AddFavourites"));
const Sorting= lazy(() => import("../../blocks/sorting/src/Sorting"));
const PostCreation= lazy(() => import("../../blocks/postcreation/src/PostCreation"));
const Posts= lazy(() => import("../../blocks/postcreation/src/Posts"));
const PostDetails= lazy(() => import("../../blocks/postcreation/src/PostDetails"));
const SocialMediaAccountLoginScreen= lazy(() => import("../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen"));
const Reviews= lazy(() => import("../../blocks/reviews/src/Reviews"));
const AddReview= lazy(() => import("../../blocks/reviews/src/AddReview"));
const SocialMediaAccountRegistrationScreen= lazy(() => import("../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen"));
const ForgotPassword= lazy(() => import("../../blocks/forgot-password/src/ForgotPassword.web"));
const ForgotPasswordConfirmation= lazy(() => import("../../blocks/forgot-password/src/ForgotPasswordConfirmation.web"));
const ForgotNewPassword= lazy(() => import("../../blocks/forgot-password/src/ForgotNewPassword.web"));
const ForgotPasswordOTP= lazy(() => import("../../blocks/forgot-password/src/ForgotPasswordOTP"));
const Dashboard= lazy(() => import("../../blocks/dashboard/src/Dashboard"));
const StripePayments= lazy(() => import("../../blocks/stripepayments/src/StripePayments"));
const TermsConditions= lazy(() => import("../../blocks/termsconditions/src/TermsConditions"));
const TermsConditionsDetail= lazy(() => import("../../blocks/termsconditions/src/TermsConditionsDetail"));
const TermsConditionsUsers= lazy(() => import("../../blocks/termsconditions/src/TermsConditionsUsers"));
const Cvresumecandidatemanagement= lazy(() => import("../../blocks/cvresumecandidatemanagement/src/Cvresumecandidatemanagement"));
const MobileAccountLoginBlock= lazy(() => import("../../blocks/mobile-account-login/src/MobileAccountLoginBlock"));
const LandingPage= lazy(() => import("../../blocks/landingpage/src/LandingPage.web"));
const Analytics= lazy(() => import("../../blocks/analytics/src/PrivateChat.web"));
const Categoriessubcategories= lazy(() => import("../../blocks/categoriessubcategories/src/Categoriessubcategories"));
const EmailConfirmation= lazy(() => import("../../blocks/email-account-registration/src/EmailConfirmation.web"));
const Certifications= lazy(() => import("../../blocks/user-profile-basic/src/Certifications/Certifications.web"));
const CertificationsDetails= lazy(() => import("../../blocks/user-profile-basic/src/Certifications/CertificationsDetails.web"));
const WorkHistory= lazy(() => import("../../blocks/user-profile-basic/src/WorkHistory/WorkHistory.web"));
const WorkHistoryDetails= lazy(() => import("../../blocks/user-profile-basic/src/WorkHistory/WorkHistoryDetails.web"))
const Skills= lazy(() => import("../../blocks/user-profile-basic/src/Skill/Skills.web"))
const UserProfilePreferences= lazy(() => import("../../blocks/user-profile-basic/src/UserProfilePreferences.web"))
const SkillsDetails= lazy(() => import("../../blocks/user-profile-basic/src/Skill/SkillsDetails.web"))
const HospitalEmailAccountLogin= lazy(() => import("../../blocks/email-account-login/src/HospitalEmailAccountLogin.web"))
// const HospitalEmailRegistration= lazy(() => import("../../blocks/email-account-registration/src/HospitalEmailRegistration.web"
const HospitalEmailRegistrationNew= lazy(() => import("../../blocks/email-account-registration/src/HospitalRegistrationWebNew.web"))
// const GigPostCreationStep1= lazy(() => import("../../blocks/postcreation/src/GigPostCreationStep1.web"
const GigPostCreationStep2= lazy(() => import("../../blocks/postcreation/src/GigPostCreationStep2.web"))
const GigPostCreationStep3= lazy(() => import("../../blocks/postcreation/src/GigPostCreationStep3.web"))
const HospitalEmailVerification= lazy(() => import("../../blocks/email-account-registration/src/HospitalEmailVerification.web"))
const PostCreationContainer= lazy(() => import("../../components/src/PostCreationContainer.web"))
const ClinicianDashboardWrapper= lazy(() => import("../../components/src/clinician/ClinicianDashboardWrapper.web"))
const ManageAddUserWeb= lazy(() => import("../../blocks/postcreation/src/ManageAddUser.web"))
const ManageAddUserConfirmation= lazy(() => import("../../blocks/postcreation/src/ManageAddUserConfirmation.web"))
const EditClinicianProfileContainer= lazy(() => import("../../blocks/user-profile-basic/src/EditClinicianProfileContainer.web"))
import CircularProgress from '@material-ui/core/CircularProgress';

export const LoadingSpinner = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>
  );
};

const UserType = {
  All: "all",
  Clinician: "clinician",
  Hospital: "hospital"
}




const routeMap = {
  addUser: {
    component: ManageAddUserWeb,
    path: "/addUser"
  },
  addUserConfirmation: {
    component: ManageAddUserConfirmation,
    path: "/addUserConfirmation"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  ForgotPasswordConfirmation: {
    component: ForgotPasswordConfirmation,
    path: "/ForgotPasswordConfirmation"
  },
  ForgotNewPassword: {
    component: ForgotNewPassword,
    path: "/ForgotNewPassword",
    roles: [UserType.All]
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  Cfmatchingalgorithm11: {
    component: Cfmatchingalgorithm11,
    path: "/Cfmatchingalgorithm11"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: "/FormApprovalWorkflow"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  Dashboardguests: {
    component: Dashboardguests,
    path: "/Dashboardguests"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  UserPublicProfileBasicBlock: {
    component: UserPublicProfileBasicBlock,
    path: "/UserPublicProfileBasicBlock",
    roles: [UserType.Clinician]
  },
  PublicProfileBasicBlock: {
    component: PublicProfileBasicBlock,
    path: "/PublicProfileBasicBlock"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Favourites: {
    component: Favourites,
    path: "/Favourites"
  },
  AddFavourites: {
    component: AddFavourites,
    path: "/AddFavourites"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock",
    roles: [UserType.All]
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword",
    roles: [UserType.All]
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Cvresumecandidatemanagement: {
    component: Cvresumecandidatemanagement,
    path: "/Cvresumecandidatemanagement"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  OnboardingHome: {
    component: EditClinicianProfileContainer,
    path: "/OnboardingHome",
    roles: [UserType.Clinician]
  },
  OnboardingCertificateLicense: {
    component: OnboardingCertificateLicense,
    path: "/onboarding/license_certificate",
    roles: [UserType.Clinician]
  },
  OnboardingEMRChatting: {
    component: OnboardingEMRChatting,
    path: "/onboarding/OnboardingEMRChatting",
    roles: [UserType.Clinician]
  },
  OnboardingCertificateLicenseEdit: {
    component: OnboardingCertificateLicenseEdit,
    path: "/onboarding/license_certificate_edit",
    roles: [UserType.Clinician]
  },
  OnboardingEducation: {
    component: OnboardingEducation,
    path: "/OnboardingEducation",
    roles: [UserType.Clinician]
  },
  OnboardingEducationEdit: {
    component: OnboardingEducationEdit,
    path: "/OnboardingEducationEdit",
    roles: [UserType.Clinician]
  },
  OnboardingRefrence: {
    component: OnboardingRefrence,
    path: "/OnboardingRefrence",
    roles: [UserType.Clinician]
  },
  OnboardingRefrenceEdit: {
    component: OnboardingRefrenceEdit,
    path: "/OnboardingRefrenceEdit",
    roles: [UserType.Clinician]
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage",
    roles: [UserType.All]
  },
  EmailAccountRegistration: {
    component: NewEmailAccountRegistration,
    path: "/EmailAccountRegistration",
    roles: [UserType.All]
  },
  ClinicianAccountVerification : {
    path : "/Clinician/confirmation",
    component : ClinicianAccountVerification,
    roles: [UserType.All]
  },
  HospitalAccountVerification : {
    path : "/hospital/confirmation",
    component : HospitalAccountVerification,
    roles: [UserType.All]
  },
  HospitalResetPassword: {
    component: HospitalResetPassword,
    path: "/HospitalResetPassword",
    roles: [UserType.All]
  },
  HospitalUserProfile: {
    component: HospitalUserProfile,
    path: "/HospitalUserProfile",
    roles: [UserType.All]
  },
  HospitalUserPayment: {
    component: HospitalUserPayment,
    path: "/HospitalUserPayment",
    roles: [UserType.All]
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  WorkHistory: {
    component: WorkHistory,
    path: "/WorkHistory",
    roles: [UserType.Clinician]
  },
  WorkHistoryDetails: {
    component: WorkHistoryDetails,
    path: "/WorkHistoryDetails",
    roles: [UserType.Clinician]
  },
  Certifications: {
    component: Certifications,
    path: "/Certifications",
    roles: [UserType.Clinician]
  },
  CertificationsDetails: {
    component: CertificationsDetails,
    path: "/CertificationsDetails",
    roles: [UserType.Clinician]
  },
  Skills: {
    component: Skills,
    path: "/Skills",
    roles: [UserType.Clinician]
  },
  SkillsDetails: {
    component: SkillsDetails,
    path: "/SkillsDetails",
    roles: [UserType.Clinician]
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  EmailConfirmation: {
    component: EmailConfirmation,
    path: "/EmailConfirmation",
    roles: [UserType.All]
  },
  UserProfilePreferences: {
    component: UserProfilePreferences,
    path: "/UserProfilePreferences",
    roles: [UserType.Clinician]
  },

  HospitalEmailAccountLogin: {
    component: HospitalEmailAccountLogin,
    path: "/HospitalAccountLogin",
    roles: [UserType.All]
  },

  HospitalForgotPassword: {
    component: HospitalForgotPassword,
    path: "/HospitalForgotPassword",
    roles: [UserType.All]
  },
  HospitalEmailRegistration: {
    component: HospitalEmailRegistrationNew,
    path: "/HospitalEmailRegistration",
    roles: [UserType.All]
  },
  HospitalEmailRegistrationNew:{
    component: HospitalEmailRegistrationNew,
    path: "/HospitalEmailRegistrationNew",
    roles: [UserType.All]
  },
  HospitalEmailVerification: {
    component: HospitalEmailVerification,
    path: "/HospitalEmailVerification",
    roles: [UserType.All]
  },
  GigPostCreationStep2: {
    component: GigPostCreationStep2,
    path: "/GigPostCreationStep2",
    // roles: [UserType.Hospital]
  },
  GigPostCreationStep3: {
    component: GigPostCreationStep3,
    path: "/GigPostCreationStep3",
    // roles: [UserType.Hospital]
  },
  PostCreationContainer: {
    component: PostCreationContainer,
    path: "/PostCreationContainer",
    // roles: [UserType.Hospital]
  },
  ClinicianDashboardWrapper: {
    component: ClinicianDashboardWrapper,
    path: "/clinician_gig",
    roles: [UserType.Clinician]
  },
  

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

const onTokenExpireRedirectToLogin = token => {
  if (jwt_decode(token).exp < Date.now() / 1000) {
    toast.error("token is expired", {
      autoClose: 2000,
      toastId: 'ToastSessionExpired'
    });
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/EmailAccountLoginBlock';
    }, 2000);
  }
};

class App extends Component {

  render() {

    const token = localStorage.getItem('authToken');
    if (token) {
      onTokenExpireRedirectToLogin(token);
    }

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <Suspense  fallback={<LoadingSpinner />}>
        <View style={{ height: '100vh', width: '100vw' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
          <ToastContainer />
        </View>
        </Suspense>
    );
  }
}

export default App;
// https://localhost:3000/users/confirmation?confirmation_token=227ftMKg7esV4NPLFBxw