import React from "react";
import { Route, Redirect } from "react-router-dom";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal
  });
}

const PrivateRoutes = ({
  roles,
  routeMap,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {

      let currentUserRole = localStorage.getItem("user_type");
      if (currentUserRole === undefined) {
        currentUserRole = "";
      }

      if (roles === undefined || roles.includes("all")) {
        return (
          <Wrapper
            element={<Component />}
            routeMap={routeMap}
            {...props}
          />
        );
      } else if (!roles.includes(currentUserRole)) {
        return (
          <Redirect
            to={{ pathname: "/EmailAccountLoginBlock", state: { from: props.location } }}
          />
        );
      } else {
        return (
          <Wrapper
            element={<Component />}
            routeMap={routeMap}
            {...props}
          />
        );
      }
    }}
  />
);

export default PrivateRoutes;